export const REDUX_STATES = {
  DEFAULT_KEY: 'listing' + new Date().getTime(),
  DEFAULT_DROPDOWN_KEY: 'dropdown' + new Date().getTime(),
  DEFAULT_ADD_KEY: 'add' + new Date().getTime(),
  DEFAULT_VIEW_KEY: 'view' + new Date().getTime(),
  DEFAULT_EDIT_KEY: 'edit' + new Date().getTime(),
  DEFAULT_DEL_KEY: 'delete' + new Date().getTime(),
  DEFAULT_FILTERS_KEY: 'filter' + new Date().getTime(),
  DEFAULT_SELECTED_KEY: 'selected' + new Date().getTime(),
  LOADING: '_loading',
  ERROR: '_error',
  ADD: '_add',
  VIEW: '_view',
  EDIT: '_edit',
  FILTERS: '_filters',
  SELECTED: '_selected',
  NEW_JOBS: 'new_jobs',
  REPLACEMENT_JOBS: 'replacement_jobs',
  CONTRACTORS: 'contractors',
  WORKERS: 'workers',
  REPORTS: 'reports',
  DOWNLOAD_FINANCIAL_REPORTS: 'downloadFinancialreport',
  CONTRACTOR_REPORTS: 'contractorReports',
  REPORTS_TOTAL: 'reportsTotal',
  DOWNLOAD_FINANCIAL_REPORTS_TOTAL: 'downloadFinancialreportsTotal',
  AUDIT_LOG: 'auditLog',
  ASCON: 'ascon',
  IMPORT_JOBS: 'importJobs',
  AUDIT_LOG_FILES: 'auditLogFiles',
  AUDIT_LOG_FILES_JOBS: 'auditLogFileJobs',
  ATTACHMENT: 'attachment',
  SERVICES: 'services',
  SERVICES_GROUP: 'services_group',
  ACTIVITIES: 'activities',
  TAKE_FIVE: 'take_five',
  PROJECT_STAGES: 'project_stages',
  REPLACEMENT_PROJECTS: 'replacement_projects',
  PROJECTS: 'projects',
  COMPANY_NAMES: 'company_names',
  METER_NUMBER_IMAGE:"meter_number_image"

};
  