/*
  Application Login Page
*/

import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";

// Antd
import { Form, Button } from "antd";

// Redux
import { useDispatch } from "react-redux";

// Components
import AuthenticationBase from "..";

// Helper
import {
  successNotification,
  errorNotification,
} from "../../../helpers/Notification";

// Form Elements
import { Text, } from "../../Common/FormElements";

// Actions

// Constants
import APP_URL from "../../../constants/ApplicationUrls";
import { API_URLS } from "../../../constants/ApiUrl";

//ACTIONS
import { addRequest } from "../../../store/actions/CRUDAction";

// Localization
import LOCALIZATION from "../../../services/LocalizationService";

function resetPassword(props) {
  const dispatch = useDispatch();
  const history = useHistory();

  const [form] = Form.useForm();
  let formRef = React.createRef();
  const [loading, setloading] = useState(false);

  const onSubmit = (data) => {
    setloading(true);
    dispatch(addRequest(API_URLS.RESET_PASSWORD, data, {}, {})).then(
      (response) => {
        form.resetFields();
        successNotification(response.data.success);
        setloading(false);
        history.push(APP_URL.AUTH.LOGIN)
      },
      (e) => {
        form.resetFields();
        setloading(false);
        errorNotification(e?.response?.data?.Failed);
      }
    );
  };

  return (
    <AuthenticationBase loading={loading}>
      <div className="app-logo">
        <h1>Reset Password</h1>
      </div>

      <Form form={form} ref={formRef} onFinish={onSubmit} layout="vertical">
        <div className="form-group">
          <Text
            label={LOCALIZATION.EMAIL_ADDRESS}
            className="form-control"
            name="email"
            required={true}
            placeholder=" "
          />
        </div>
        <Form.Item>
          <Button
            block
            size="large"
            type="primary"
            htmlType="submit"
            className="mt-3"
          >
            {LOCALIZATION.RESET_PASSWORD}
          </Button>
        </Form.Item>
      </Form>
      <Button
        onClick={() => history.push(APP_URL.AUTH.LOGIN)}
        type="text primary"
      >
        {LOCALIZATION.BACK_TO_LOGIN}?
      </Button>
    </AuthenticationBase>
  );
}

export default resetPassword;
