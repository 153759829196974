/*
  Actual application starts from this file
*/

import React from 'react';
import { 
  BrowserRouter, 
  Switch, 
  Route 
} from 'react-router-dom'; //https://css-tricks.com/the-hooks-of-react-router/
import Loadable from 'react-loadable';

// Redux
import {
  useSelector
} from 'react-redux';

// Antd
import { notification } from 'antd';

// Constants
import APP_URL from './constants/ApplicationUrls';
import { NOTIFICATION_DURATION } from './constants/General';

// Components
import PrivateRoute from './components/PrivateRoute';
import Loading from './components/Loading';
import Page500 from './components/ErrorPages/Page500';

// Auth Components
import Login from './components/Authentication/Login';
import Reset_password from './components/Authentication/resetPassword';
import Confirm_password from './components/Authentication/ConfirmPassword';

// Services
import StorageService from './services/StorageService';

// Styling
import './assets/sass/style.scss';

// Localization
import LOCALIZATION from './services/LocalizationService';

// Package.json
const packageJson = require('../package.json');

// Notification Configuration for whole application
notification.config({
  placement: 'topRight',
  duration: NOTIFICATION_DURATION,
  rtl: false
});

// Base Layout
const BaseLayout = Loadable({
  loader: () => import('./components/BaseLayout'),
  loading: Loading
});

function App() {

  // Is New Job
  const isReplacement = StorageService.instance.getIsReplacement();

  return (
    <div className={`App ${ isReplacement? 'theme-blue': '' }`}>
      <BrowserRouter basename={ packageJson?.homepage || '' }>
        <Switch>
          {/* 
            Open Routes Here 
          */}

          {/* Login */}
          <Route
            path={ APP_URL.AUTH.LOGIN }
            name={ LOCALIZATION.LOGIN }
            component={ Login }
          />

          {/* Reset password */}
          <Route
            path={ APP_URL.AUTH.RESET_PASSWORD }
            name= {LOCALIZATION.RESET_PASSWORD}
            component={ Reset_password }
          />

          {/* Confirm password */}
          <Route
            path={ APP_URL.AUTH.CONFIRM_PASSWORD }
            name= { LOCALIZATION.CONFIRM_PASSWORD }
            component={ Confirm_password }
          />

          {/* Page 500 */}
          <Route
            path={ APP_URL.ERROR.PAGE500 }
            name={ LOCALIZATION.PAGE_500 }
            component={ Page500 }
          />

          {/* Restricted Routes Here */}
          <PrivateRoute 
            component={ BaseLayout } 
          />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;