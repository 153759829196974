export const API_URLS = {
  AUTHENTICATE: {
    LOGIN:'/login/',
    USER_PERMISSIONS:'/get_user_permissions/'
  },
  JOBS: "/jobs/",
  NEW_JOBS: "/jobs/",
  NEW_JOBS_DOWNLOAD_CSV: "/jobs/?download=true",
  CHANGE_PASSWORD:"/change-password/",
  REPLACEMENT_JOBS: "/replacement_jobs/?stage=:id",
  
  VIEW_JOB: "/jobs/:id/",
  UPDATE_JOB: "/jobs/:id/",
  
  VIEW_REPLACEMENT_JOB: "/replacement_jobs/:id/",
  UPDATE_REPLACEMENT_JOB: "/replacement_jobs/:id/",

  VIEW_JOB_ATTACHMENT: "/jobs/attachment/?job=:id",
  DELETE_ATTACHMENT: "/jobs/attachment/:id",
  UPLOAD_ATTACHMENT: "/jobs/attachment/",

  VIEW_REPLACEMENT_JOB_ATTACHMENT: "/replacement_jobs/attachment/?replacement_job=:id",
  DELETE_REPLACEMENT_ATTACHMENT: "/replacement_jobs/attachment/:id",
  UPLOAD_REPLACEMENT_ATTACHMENT: "/replacement_jobs/attachment/",
  
  GET_TAKE_FIVE: "/take_five/?job=:id",
  POST_TAKE_FIVE: "/jobs/take-five",
  UPDATE_TAKE_FIVE: "/take_five/:id/",
  CREATE_TAKE_FIVE: "/take_five/",
  GET_SERVICES_BY_JOB: "/service/?job=:id",
  GET_SERVICE_GROUP_BY_JOB_TYPE: "/service/:type/",
  GET_ACTIVITIES_BY_JOB: "/jobs/activities/?job_id=:id",
  IMPORT_JOBS: "/jobs/import/",
  IMPORT_REPLACEMENT_JOBS: "/replacement_jobs/import/",
  EXPORT_JOBS: "/jobs/export/",
  DELETE_JOB_BY_NOTICATION_NO: "/jobs/:notification_no/",
  DELETE_REPLACEMENT_JOB_BY_NOTICATION_NO: "/replacement_jobs/:notification_no/",
  CONTRACTORS: "/contractors/",
  GET_CONTRACTOR:"/contractors/?user__groups__name=:group",
  COMPANY_NAMES: "/contractors/?company_name=True&user__groups__name=:group",
  WORKERS: "/workers/",
  GET_WORKER:"/workers/?user__groups__name=:group",
  WORKERS_BY_CONTRACTOR_ID: "/workers/?assigned_contractor=:id",
  REPORT: "/reporting/",
  REPORT_REPLACEMENT: "/replacement_reports/",
  REPLACEMENT_ASCON: "/replacement_jobs/attachment/",
  NEW_ASCON: "/jobs/attachment/",
  REPORT_CONTRACTOR: "/reporting/contractor/",
  REPORT_CONTRACTOR_REPLACEMENT: "/replacement_reports/contractor/",
  AUDIT_LOG: "/files_logs/actions/",
  JOB_FILE_LOGS: "/jobs/files/",
  REPLACEMENT_JOB_FILE_LOGS: "/replacement_jobs/files/",
  PROJECT_STAGES: "/replacement_stages/?project=:id",
  CHECK_METER_NUMBER: "/jobs/check_meter_number/?id=:id&meter_number=:value",
  CHECK_METER_NUMBER_REPLACEMENT: "/replacement_jobs/check_meter_number/?id=:id&meter_number=:value",
  ADD_ATCO: "/jobs/atco_approval/",
  UPDATE_ATCO: "/jobs/atco_approval/:id/",
  ADD_REPLACEMENT_ATCO: "/replacement_jobs/atco_approval/",
  UPDATE_REPLACEMENT_ATCO: "/replacement_jobs/atco_approval/:id/",
  ADD_REPLACEMENT_JOB: "/replacement_jobs/",

  RESET_PASSWORD : "/reset_password/",
  PASSWORD_RESET_CONFIRM : "/password_reset_confirm/",

  REPLACEMENT_PROJECTS: "/replacement_projects/",
  REPLACEMENT_STAGES : "/replacement_stages/",

  METER_NUMBER_IMAGE_UPLOAD: "/jobs/upload_meter_image/"
}
